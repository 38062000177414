import { useEffect } from 'react';
import { navigate } from 'gatsby';

export default () => {
    useEffect(() => {
        (function() {
            var inMobile = window.location.href.match(/mobile/i) !== null;
            if (inMobile) return;
            
            var sUserAgent = navigator.userAgent.toLowerCase();
        
            var bIsIpad = sUserAgent.match(/ipad/i) !== null;
        
            var bIsIphoneOs = sUserAgent.match(/iphone os/i) !== null;
        
            var bIsMidp = sUserAgent.match(/midp/i)!== null;
        
            var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) !== null;
        
            var bIsUc = sUserAgent.match(/ucweb/i) !== null;
        
            var bIsAndroid = sUserAgent.match(/android/i) !== null;
        
            var bIsCE = sUserAgent.match(/windows ce/i) !== null;
        
            var bIsWM = sUserAgent.match(/windows mobile/i) !== null;
            if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
                // window.location.href = '/mobile/';
                navigate('/mobile/', { replace: true });
                console.log('移动端网站地址');
            } else {
                // window.location.href= '/home/';
                navigate('/home/', { replace: true });
                // window.location= 'PC端网站地址';
                console.log('PC端网站地址')
            }
        })();
    }, []);
    return null
};
